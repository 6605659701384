export const MAIN_MENU_OPENED_ONCE = 'mainMenuOpenedOnce';

export const saveToLocalStorage = (key, value) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

export const getFromLocalStorage = (key) => {
  if (window.localStorage) {
    return window.localStorage.getItem(key);
  }
  return null;
}

