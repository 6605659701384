import React from 'react';
import PropTypes from "prop-types"
import {TimelineMax, TweenMax, Expo} from "gsap/TweenMax";
import cn from 'classnames';

import css from './MainMenu.module.css';
import { navigate } from "gatsby"
import { MAIN_MENU_OPENED_ONCE, saveToLocalStorage } from "../shared/utils/local-storage"

export class MainMenu extends React.Component {
  isClosing = false;
  componentDidMount() {
    this.show();
    saveToLocalStorage(MAIN_MENU_OPENED_ONCE, true)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isMenuOpen) {
      this.show();
    } else {
      this.hide();
    }
  }

  show = () => {
    this.isClosing = false;
    /*if (TweenMax.isTweening(['.animate-item', '.fa.fa-times'])) { return };*/
    TweenMax.killTweensOf('.animate-item');
    TweenMax.killTweensOf('.fa.fa-times');
    const tl = new TimelineMax();
    // tl.eventCallback("onComplete", () => console.log('comple init')); //sets the onComplete

    /*tl.from(`.${css.main_menu_wrapper}`, 0.3, {opacity:0});
    tl.from(`.${css.main_menu}`, 0.4, {scaleX: 0}, '-0.3');*/
    tl.set(['.animate-item', '.fa.fa-times'], {scale:0.5, opacity:0})
    /*tl.add(
      TweenMax.staggerTo('.animate-item', 0, {
      scale:0.5, opacity:0
      }, 0.1)
    )*/
    tl.add(
      TweenMax.staggerTo('.animate-item', 0.5, {
        scale:1, opacity:1, ease: Expo.easeInOut, force3D:true,
        delay: 0.5,
        stagger: {amount: 0.3}
      }, 0.1)
    );
    tl.add(TweenMax.to(`.fa.fa-times`, 0.2, {opacity:1, scale: 1, force3D:true}));

  }

  hide = (url) => {
    if (
      this.isClosing &&
      TweenMax.isTweening(['.animate-item', '.fa.fa-times'])
    ) { return };

    this.isClosing = true;
    TweenMax.killTweensOf('.animate-item');
    TweenMax.killTweensOf('.fa.fa-times');
    TweenMax.set('.' + css.main_menu_items, { className: '+=disable-events'})

    const tl = new TimelineMax();

    tl.add(TweenMax.to(`.fa.fa-times`, 0.3, {opacity:0, force3D:true}));
    tl.add(
      TweenMax.staggerTo('.animate-item', 0.2, {
        scale:1.2, opacity:0, ease: Expo.easeInOut, force3D:true,
        stagger: {amount: -0.3}
      })
    );

    tl.eventCallback("onComplete", () => {
      this.props.onToggleMenu()
      setTimeout(() => {
        if (url) {
          this.goto(url);
        }
      }, 600);
    });
  }

  goto(url) {
    navigate(url)
  }
  render() {
    console.log(this.props)
    return <div className={css.main_menu_wrapper}>
      <div className={css.main_menu}>
        <div className={css.main_menu_items}>
          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/'})}>HOME</span>
          </div>

          {/*<span className={cn('animate-item', css.main_menu_item_bullet)}>•</span>*/}

          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/about/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/about/'})}>ABOUT ME</span>
          </div>

          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/services/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/services/'})}>SERVICES</span>
          </div>

          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/training/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/training/'})}>TRAINING</span>
          </div>

          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/gallery/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/gallery/'})}>PHOTOS</span>
          </div>

          <div className={cn('animate-item')}>
            <span
              onClick={() => this.hide('/contacts/')}
              className={cn(css.main_menu_item, {[css.active]: this.props.path === '/contacts/'})}>CONTACTS</span>
          </div>


          <div className={ css.main_menu_item}>
            <i className="fa fa-times pointer" onClick={() => this.hide()}></i>
          </div>

          {/*


          <span className={cn('animate-item', css.main_menu_item_bullet)}>•</span>
          <Link to="/" className={cn('animate-item', css.main_menu_item, css.main_menu_item2)}>
            <span className="title_text_l">VIDEO CORSO</span>
          </Link>
          <span className={cn('animate-item', css.main_menu_item_bullet)}>•</span>
          <Link to="/" className={cn('animate-item', css.main_menu_item, css.main_menu_item3)}>
            <span className="title_text_l">CONTACTS</span>
          </Link>
          */}
        </div>
      </div>
    </div>
  }
};


MainMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  onToggleMenu: PropTypes.func,
}
