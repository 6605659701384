import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../core/header"
import "./layout.css"
import '../shared/css/grid.css';


export class Layout extends React.Component {
  state = { menuIsOpen: false };

  showMenu = (open) => {
    this.setState({ menuIsOpen: open})
  };

  toggle = () => {
    this.setState(state => ({ menuIsOpen: !state.menuIsOpen}))
  }

  render() {
    const { children }  = this.props;

    return (<StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title,
            authorName,
            authorSurname
          }
        }
      }
    `}
      render={data => (
        <>
          <div
            style={{
              margin: `0 auto`,
              /*maxWidth: 960,*/
              paddingTop: 0,
            }}
          >
            <main>{children}</main>
            <Header
              {...this.props}
              isMenuOpen={this.state.menuIsOpen}
              siteMetadata={data.site.siteMetadata}
              onToggleMenu={() => this.toggle()}
            />
          </div>
        </>
      )}
    />)
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
