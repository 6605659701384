import PropTypes from "prop-types"
import React from "react"
import css from './header.module.css';
import cn from 'classnames';
import { MainMenu } from "./MainMenu"
import MyBlogLogo from '../images/_static/MyBlogIcon.png';

class Header extends React.Component {
  onToggleMenu = () => {
    if (!this.props.isMenuOpen) {
      this.props.onToggleMenu();
    }
  }
  render() {
    const { isMenuOpen, onToggleMenu, siteMetadata: { authorName = '', authorSurname = ''}} = this.props;

    return (
      <header className={css.header}>
        <div className={css.navbar_wrapper}>
          <div className={css.navbar}>
            {/*<Link
                to="/"
                className={css.navbar_item}
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >*/}
            <div style={{ color: 'white' }} onClick={this.onToggleMenu}>
                <span
                  className={cn(css.logo, 'noSelect', {[css.logo_opened_menu]: isMenuOpen})}
                  >
                    {/*<i className={cn('fa', {'fa-bars': !this.props.isMenuOpen}, {'fa-times': this.props.isMenuOpen})}></i>*/}
                    {isMenuOpen ? null : <i className={cn(css.logo_icon, 'fa fa-bars')} style={{paddingTop: '0.5rem'}}></i>}
                    {isMenuOpen ? <MainMenu path={this.props.pageResources.page.path} isMenuOpen={isMenuOpen} onToggleMenu={onToggleMenu}/> : null }

                </span>
              <span className={css.logo_name}>{authorName.toUpperCase()}</span>
              <span className={css.logo_surname}>{authorSurname.toUpperCase()}</span>
            </div>
            {/*</Link>*/}

            <div className={css.navbar_item}>
              <div className={css.socials}>

                <a href="http://training.fabiobiondi.io" target="_blank" rel="noopener noreferrer"
                   className="hide-on-mobile">
                  <img src={MyBlogLogo} alt="MyBlog" style={{height: 25}} />
                </a>

                <a href="mailto:hello@fabiobiondi.io" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-envelope-o"></i>
                </a>

                <a href="https://www.facebook.com/biondifabio/" target="_blank" rel="noopener noreferrer"
                  className="hide-on-mobile-low-res">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/user/BiondiFabio" target="_blank"  rel="noopener noreferrer"
                  className="hide-on-mobile">
                  <i className="fa fa-youtube-play"></i>
                </a>
                <a href="https://www.instagram.com/biondifabio/" target="_blank"  rel="noopener noreferrer"
                   className="hide-on-mobile">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://twitter.com/biondifabio" target="_blank"  rel="noopener noreferrer"
                   className="hide-on-mobile">
                  <i className="fa fa-twitter"></i>
                </a>

              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  isMenuOpen: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  siteMetadata: PropTypes.object,
}

Header.defaultProps = {
  siteMetadata: {}
}

export default Header
